<template>
	<div class="dealer">
		<div class='search_box'>
			<van-search v-model="servalue" placeholder="请输入出库单号" class="seaech_input" @input="onSearch" />
			<span class='serach_btn' @click="visiit=true">筛选</span>
		</div>	
		<div>
				<van-tabs
				v-model:active="tab_index"
				@change="tabs_change"
				title-active-color="#00B578"
				color="#00B578">
					<van-tab
						:name="item.value"
						v-for="item in tab_list"
						:key="item.value"
						:title="item.label"
					>
					</van-tab>
				</van-tabs>
				
			</div>
		<!-- <van-pull-refresh v-model="refreshing" @refresh="onRefresh"> -->
			<div class="list"  v-if="list.length">
				<van-list   :immediate-check="false" v-model="loading" :finished="finished" finished-text="没有更多了" @load="onLoad">
					<div class="material" v-for="(item,index) in list" :key="index" @click="go_details(item.id)">
						<div class="material_title">
							<span>润田公司销售出库单</span>
							<span
								:class="item.is_read==1?'is_read':'no_read'">{{item.is_read==1?'经销商已读':'经销商未读'}}</span>
						</div>
						<div class='good_info'>
							<div class="info_item">
								<span>出库时间：</span><span>{{item.create_time}}</span>
							</div>
							<div class="info_item">
								<span>客户名称：</span><span>{{item.consignee}}</span>
							</div>
							<div class="info_item">
								<span>出库单号：</span><span>{{item.outcomeId}}</span>
							</div>
							<div class="info_item">
								<span>产品明细：</span>
								<span>
									<span style="color: #333;"
										v-for="(good,index) in item.outcomeGoodsInfoList.slice(0,3)" :key="index">{{good.goodsName}}<br>{{item.outcomeGoodsInfoList.length>3?'....':''}}</span>
								</span>
							</div>
							<div class="info_item">
								<span>发货金额：</span><span>{{item.totalOutcomeMoney.toLocaleString()}}元</span>
							</div>
						</div>
						<div class='show_info' >
							<p><span>查看详情</span><span>
									<van-icon name="arrow" />
								</span></p>
						</div>
					</div>
				</van-list>
			</div>
		<!-- </van-pull-refresh> -->


		<!-- 
		<div class='list'>
			<div class="material" v-for="(item,index) in list">
				<div class="material_title">
					<span>润田公司销售出库单</span>
					<span :class="item.is_read==1?'is_read':'no_read'" >{{item.is_read==1?'经销商已读':'经销商未读'}}</span>
				</div>
				<div class='good_info'>
					<div class="info_item">
						<span>发送时间：</span><span>{{item.create_time}}</span>
					</div>
					<div class="info_item">
						<span>客户名称：</span><span>{{item.consignee}}</span>
					</div>
					<div class="info_item">
						<span>出库单号：</span><span>{{item.outcomeId}}</span>
					</div>
					<div class="info_item">
						<span>产品明细：</span>
						<span>
							<span style="color: #333;"
								v-for="(good,index) in item.outcomeGoodsInfoList.slice(0,3)">{{good.goodsName}}{{index != (item.outcomeGoodsInfoList.length-1)?',':''}}{{item.outcomeGoodsInfoList.length>3?'....':''}}</span>
						</span>
					</div>
					<div class="info_item">
						<span>发货金额：</span><span>{{item.totalOutcomeMoney}}</span>
					</div>
				</div>
				<div class='show_info' @click="go_details(item.id)">
					<p><span>查看详情</span><span>
							<van-icon name="arrow" />
						</span></p>
				</div>
			</div>
 -->
		<!-- </div> -->
		<van-popup v-model="visiit" position="right" style="height: 96%;padding: 16px;">
			<!-- <div class='lefd_box' v-show=""> -->
			<div class='lefd_draw'>
				<div class='mar_top'>
					<p class="lable_p">时间范围</p>
					<p class='date_box'>
						<span class="data_itme" @click="show_data(1)">{{date?date:'开始时间'}}
							<van-icon name="arrow-down" class="arrow-down" />
						</span>-<span class="data_itme" @click="show_data(2)">{{date2?date2:'结束时间'}}
							<van-icon name="arrow-down" class="arrow-down" />
						</span>
					</p>
				</div>
				<div class='mar_top'>
					<p class="lable_p">出库单类型</p>
					<p class='date_box'>
						<p class='select_box' @click="select_show=true">
							<span>{{select_info?select_info:"请选择出库单类型"}}
								<van-icon name="arrow-down" class="arrow-down2" />
							</span>
						</p>
					</p>
				</div>
				<div class='botton_btn'>
					<p @click="reset_info">重置</p>
					<p @click="submit_search">搜索</p>
				</div>

			</div>
		</van-popup>
		<!-- </div> -->
		<van-popup v-model="detail_show" position="bottom" :style="{height:'100%'}">
			<Detail v-if="detail_show" :id="detail_id" @detail_return="detail_return"></Detail>
		</van-popup>
		<van-popup v-model="select_show" position="bottom">
			<van-picker show-toolbar :columns="columns" @cancel="onCancel" @confirm="onConfirm2" />
		</van-popup>
		<van-popup v-model="data_show" position="bottom">
			<van-datetime-picker v-model="currentDate" @confirm='confirm' type="date" :min-date="minDate"
				:max-date="maxDate" />
		</van-popup>
		<van-empty description="暂无数据" v-if='!list.length' />
	</div>
</template>

<script>
	import Detail from './Details.vue'
	import {
		DatetimePicker,
		Picker,
		Toast,
		Popup,
		Icon,
		Search,
		List,
		Empty
	} from 'vant';
	import {
		auth,
		get_bill_list
	} from '@/assets/js/api.js';
	import moment from "moment"
	export default {
		components: {
			DatetimePicker,
			Picker,
			Popup,
			Icon,
			Search,
			Empty,
			Detail
		},
		data() {
			return {
				detail_show:false,
				detail_id:0,
				tab_index:0,
				tab_list: [	
					{
						label:'未签收',
						value:0,
					},
					{
						label:'已签收',
						value:1,
					}
				],
				refreshing: false,
				loading: false,
				finished: false,
				list: [],
				servalue: '',
				data_show: false,
				select_show: false,
				columns: ['全部','桶装', '瓶装'],
				minDate: new Date(2020, 0, 1),
				maxDate: new Date(2025, 10, 1),
				currentDate: new Date(),

				visiit: false,
				info: {
					goods_data: []
				},
				id: '',
				date: '',
				date2: '',
				query: {},
				selectItem: '',
				data_type: '',
				select_info: "",
				page: 1, //请求第几页
				total: 0, //总共的数据条数
			}
		},
		created() {
			if(this.$route.query.token){
				localStorage.setItem('token', this.$route.query.token);
			}
			this.get_list()
		},
		methods: {
			//滚动加载时触发，list组件定义的方法
			onLoad() {
				this.page++;
				this.get_list();
			},
			submit_search() {
				if (this.select_info == '桶装') {
					this.type = 1
				}
				if (this.select_info == '瓶装') {
					this.type = 2
				}
                if (this.select_info == '全部') {
                	this.type = ''
                }
				this.page=1
                this.list=[]
				this.get_list()
				this.visiit = false;
			},
			onSearch() {
				this.page = 1;
				this.get_list(1)
				this.visiit = false;
			},
			get_list(type=0) {
				let params = {
					sign_status:this.tab_index,
					type: this.type,
					start_time: this.date,
					end_time: this.date2,
					ordersn: this.servalue,
					page: this.page
				};
				get_bill_list(params).then(res => {
					let rows = res.data.list.data; //请求返回当页的列表
					this.loading = false;
					this.total = res.data.list.total;
					if (rows == null || rows.length === 0) {
						this.finished = true;
					}
					if((this.type||this.date||this.date2||this.servalue)&& this.page==1){
						this.list=[];
						if (rows == null || rows.length === 0) {
							this.finished = true;
							return;
						}else {
							this.finished = false;
						}
					}
					// 将新数据与老数据进行合并
					if (type == 1) {
						this.list = rows;
					} else {
						this.list = this.list.concat(rows);
					}
					//如果列表数据条数>=总条数，不再触发滚动加载
					if (this.list.length >= this.total) {
						this.finished = true;
					}
					this.loading = false;
				});
			},
			onRefresh() {
				this.page = 1
				this.finished = false;
				this.loading = true;
				this.get_list();
			},

			onConfirm2(value, index) {
				this.select_info = value
				this.select_show = false
			},
			reset_info() {
				this.date = ''
				this.date2 = ''
				this.select_info = ''
				this.type = ''
				this.list=[];
				this.finished=false;
			},
			onCancel() {
				this.select_show = false
			},
			confirm(value) {
				let date = moment(value).format('YYYY-MM-DD')
				if (this.data_type == 1) {
					this.date = date
				} else {
					this.date2 = date
				}
				this.data_show = false
			},
			show_data(type) {
				this.data_type = type
				this.data_show = true
			},
			onChange(picker, value, index) {
				this.select_info = value
			},
			go_details(ids) {
				this.detail_id = ids
				this.detail_show = true
			},
			tabs_change(e) {
				this.finished = false
				this.tab_index = e
				this.get_list(1)
			},
			detail_return(e) {
				this.detail_show = false
				this.tabs_change(this.tab_index)
			}

		}
	}
</script>

<style lang="less" scoped>
	@import '../assets/css/base.less';

	page {
		background: #F7F7F7;
	}
	/deep/.van-empty__image{
		margin-top: 100px;
	}

	.material {
		margin-top: 10px;
		background: #fff;

		.material_title {
			height: 47px;
			line-height: 47px;
			opacity: 1;
			font-size: 30rpx;
			border-bottom: 1px solid #E4E3E5;
			padding-left: 16px;
			padding-right: 16px;
			display: flex;

			span:nth-child(1) {
				flex: 2;
				font-size: 16px;
			}

			span:nth-child(2) {
				flex: 1;
				text-align: right;
			}
		}

		.material_box {
			padding-left: 16px;
			padding-right: 16px;
			padding-bottom: 8px;

			.material_item {
				display: flex;
				font-size: 15px;
				color: #333333;
				margin-bottom: 5px;

				span:nth-child(1) {
					flex: 3;
					span-align: left;
					color: #888888;
				}

				span:nth-child(2) {
					flex: 1;
					color: #333333;
					span-align: center;
				}

				span:nth-child(3) {
					flex: 1;
					span-align: center;
				}
			}

			.material_item:nth-child(1) {
				height: 34px;
				line-height: 34px;
				margin-bottom: 0rpx;
			}
		}
	}

	.list {
		padding: 0px 15px;
	}


	.header_top {
		display: flex;
		height: 38px;
		background: #fff;
		border-bottom: 1px solid #e6e6e6;
		padding: 11px 16px;

		.user_info {
			.timer {
				height: 14px;
				opacity: 1;
				font-size: 13px;
				font-weight: 400;
				text-align: left;
				color: #aaacb2;
				line-height: 16px;
			}
		}
	}

	.good_info {
		padding: 0px 16px;
		font-size: 14px;
		color: #666666;

		// height: ;
		p {
			line-height: 30px;
		}

		.info_item {
			// height: 28px;
			font-size: 14px;
			display: flex;
			margin: 7px 0px;

			span:nth-child(1) {
				flex: 1.1;
				color: #888888;
			}

			span:nth-child(2) {
				color: #333333;
				flex: 3.5;
				text-align: left;
			}

			div:nth-child(2) {
				display: 3.5;
				color: #333333;
			}


		}
	}

	.other_info {
		padding: 4px 16px;

		p {
			height: 42px;
			line-height: 42px;
			display: flex;
			font-size: 16px;

			span:nth-child(1) {
				flex: 1;
				color: #666666;
			}

			span:nth-child(2) {
				flex: 1;
				text-align: right;
				color: #000000;
			}
		}

		p:nth-child(1) {
			border-bottom: 1px solid #e6e6e6;
		}
	}

	.h30 {
		height: 30px;
	}

	.material {
		margin-top: 12px;
		border-radius: 6px;

		.material_title {
			border: none;
		}

		.other_info {
			padding: 4px 16px;
			padding-top: 0px;

			p {
				height: 42px;
				line-height: 42px;
				display: flex;
				font-size: 16px;

				span:nth-child(1) {
					flex: 1;
					color: #666666;

				}

				span:nth-child(2) {
					flex: 1;
					text-align: right;
					color: #000000;
				}
			}

			p:nth-child(1) {
				border-bottom: 1px solid #e6e6e6;
			}
		}
	}

	.show_info {
		height: 44px;
		opacity: 1;
		line-height: 44px;
		padding: 0px 16px;
		border-top: 1px solid #E5E5E5;
		border-radius: 0px 0px 6px 6px;

		p {
			display: flex;

			span {
				flex: 1;
				font-size: 14px;
			}

			span:nth-child(2) {
				text-align: right;
				color: #888888;
				font-size: 14px;
			}
		}
	}

	.search_box {
		height: 39px;
		// line-height: 25px;
		background: #fff;
		padding: 8px 15px;
		position: relative;
		display: flex;
		align-items: center;

		.seaech_input {
			height: 37px;
			flex: 1;
			opacity: 1;
			flex: 1;
			background: #f6f7f8;
			border: 1px solid #ecedef;
			border-radius: 3px;
			padding-left: 5px;
		}

		.serach_btn {
			font-size: 15px;
			margin-left: 10px;
			color: #666666;
		}
	}

	.lefd_box {
		width: 100%;
		height: 100%;
		position: fixed;
		top: 0;
		left: 0;
		background: rgba(0, 0, 0, .4);
		padding-top: 10px;

		.lefd_draw {
			position: fixed;
			padding-left: 10px;
			right: 0;
			top: 0;
			width: 310px;
			height: 100%;
			background: #fff;

		}

	}

	.date_box {
		display: flex;
		align-items: center;
		color: #999999;
		margin-left: 16px;

		.data_itme {
			color: #999999;
			width: 135px;
			height: 30px;
			text-align: center;
			line-height: 30px;
			opacity: 1;
			font-size: 14px;
			background: #ffffff;
			border: 1px solid #e2e2e2;
			border-radius: 3px;
		}
	}

	.lable_p {
		font-size: 15px;
		padding-left: 15px;
		margin-bottom: 5px;
	}

	.mar_top {
		margin-top: 16px;
	}

	.select_box {
		width: 272px;
		height: 35px;
		line-height: 35px;
		opacity: 1;
		background: #ffffff;
		border: 1px solid #e2e2e2;
		border-radius: 3px;
		margin-left: 15px;
		margin-top: 5px;
		padding-left: 3px;
		padding-right: 5px;
		color: #999999;
		font-size: 14px;
		padding-left: 5px;
		position: relative;
	}

	.botton_btn {
		display: flex;
		position: absolute;
		bottom: 0;
		right: 0;
		width: 100%;

		p {
			flex: 1;
			text-align: center;
			background: #feffff;
			height: 50px;
			line-height: 50px;
			color: #000000;
			font-size: 16px;
			border-top: 1px solid #ECEDEE;
		}

		p:nth-child(2) {
			color: #fff;
			border: none;
			background: #007BFF;
		}
	}

	.arrow-down {
		margin-left: 28px;
		// line-height: 30px;
	}

	.arrow-down2 {
		// margin-left: 120px;
		position: absolute;
		top: 10px;
		right: 10px;
	}

	.no_read {
		color: #FF3B30;
	}

	.is_read {
		color: #00B578;
	}

	.van-popup--righ {
		height: 100%;
	}
</style>
