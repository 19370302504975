let debug = true;
debug = false
let config = {
	hostUrl:'http://crm-dev.waakuu.com'
}
if(!debug){
	// 正式环境
	config.hostUrl='https://crm.runtian.cn'
}

export default config;